.main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 3rem;
    overflow-x: auto;
    overflow-y: hidden;
}
.checkouts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
}
.checkout_content {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;
}

.proceed_btn {
    width: 100%;
    background-color: #4CAF50;
    padding: 5px;
    background-color: #17ae47;
    border: 0;
    border-radius: 17px;
    color: white;
    font-weight: 600;
    outline: none;
}