.container {
	margin: 0 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.page_btn {
	width: 30px;
	height: 30px;
	outline: none;
	border: none;
	font-size: 14px;
	font-weight: 500;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1px;
	box-shadow: var(--box-shadow);
	cursor: pointer;
	background-color: rgb(238, 236, 236);
}

.active {
	background-color: #2196F3;
	box-shadow: 0px 7px 17px -7px #3998e6;
	color: #ffffff;
}
