.main {
   background-color: #C8DEB8;
   min-height: 50vh;
   display: flex;
   justify-content: center;
}


.head_text {
    text-align: center;
    color: #17ae47;
}

.login_container {
    width: 100%;
    max-width: 45%;
    background-color: #F2F7EE;
    border-radius: 5px;
    margin: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    
}


.announcement_field {
    display: flex;
    gap: 15px;
}

@media screen and (max-width: 1024px) {
    .login_container {
        max-width: 70%;
    }
}





@media screen and (max-width: 768px) {
    .login_container {
        max-width: 80%;
    }
}

@media screen and (max-width: 576px) {
    .login_container {
        max-width: 92%;
        padding: 10px;
    }
    .announcement_field {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 15px;
        border-top: 0.5px solid #bababa;
        border-bottom: 0.5px solid #bababa;
        padding: 10px 0;
    }
    .head_text {
        font-size: 1.5rem;
    }
}



@media screen and (max-width: 270px) {
    .head_text {
        font-size: 1rem;
    }
}




form {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input {
    border: 0;
    background-color: #e4e4e4;
    padding: 10px 13px;
    padding-right: 30px;
    border-radius: 17px;
    outline:  none;
    width: 100%;
}

.input:focus {
    outline: 0.5px solid #17ae47;
}

.button {
    padding: 5px;
    background-color: #17ae47;
    border: 0;
    border-radius: 17px;
    color: white;
    font-weight: 600;
    outline: none;
}

button:active {
    background-color: #138336;
}

h1 {
    font-weight: 800;
    font-size: 2.1rem;
}



.phone_wrapper {
    position: relative;
}


.phone_prefix {
    position: absolute;
    left: 10px;
    top: 10px;
}


.phone_input {
    padding-left: 50px;
}


.error {
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
    font-style: italic;
}