.loading {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  padding: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}



.downloadButton {
  background-color: rgba(0, 128, 0, 0.746);
  color: white;
  outline: none;
  border-radius: 10px;
  padding: 4px 10px;
  border: none;
  margin-left: 10px;
}

.downloadButton:hover {
  background-color: rgba(0, 128, 0, 0.581);
}

.downloadButton:active {
  background-color: green;
}

.downloadedButton {
  background-color: rgb(15, 82, 15);
}

.ticket {
  margin: 10px 0;
}

.ticketList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ticketItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ticketDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ticketInfo {
  display: flex;
}

.ticketFloor,
.ticketLine,
.ticketSeat {
  margin: 0 10px;
  font-weight: bold;
}

.mainDiv {
  min-height: 600px;
  padding: 0 10px;
}

.warning {
  font-style: italic;
  color: red;
}

.successContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media (max-width: 390px) {

  .downloadButton {
    margin-top: 10px;
    width: 100%;
  }

  .ticketDetails {
    flex-direction: column;
  }

  .ticketFloor,
  .ticketLine,
  .ticketSeat {
    font-size: 12px;
  }
}


