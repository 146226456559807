.article{
  margin: 10px
}

 .card-holder {
   margin: 1em 0;
 }
 
 .img-box {
  display: flex;
  justify-content: center;
  padding: 10px;
 }

.img-fluid{
  width: 30em;
}

 .card-box {
   font-weight: 800;
   padding: 1em;
   border-radius: 0.25em;
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
 }
 .bg-news {
   background: -webkit-linear-gradient(0.35turn, #5bbee9, #ebf8e1, #ffb76a);
   background: -o-linear-gradient(0.35turn, #5bbee9, #ebf8e1, #ffb76a);
   background: -moz-linear-gradient(0.35turn, #5bbee9, #ebf8e1, #ffb76a);
   background: linear-gradient(0.35turn, #5bbee9, #ebf8e1, #ffb76a);
 }


 
 .title{
   font-family: 'Abhaya Libre', serif;
   font-size: 1em;
   color: #2C3E50;
   border-bottom: 1px solid rgba(0,0,0,0.1);
   margin-bottom: 0.8em;
   font-weight: 600;
   padding-bottom: 8px;
 }
 .card-details{
   padding: 25px 25px 15px;
 }
 
 
 .card-details label{
   font-family: 'Abhaya Libre', serif;
   font-size: 14px;
   font-weight: 400;
   margin-top: 10px;
   color: #79818a;
   text-transform: uppercase;
 }
 

/* ======================= Card Pursatlar=========================== */
.card-spectacles {
  border-radius: 10px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
  border: none;
  margin-bottom: 5px;
}
.card-spectacles:hover {
  cursor: pointer;
}

.profile-card {
  position: relative;
}

.profile-card .card-block {
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  background: linear-gradient(transparent, rgba(0,0,0,0.72));
}

.card-block {
  padding: .5rem;
}

.btn-info {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
}
.bg-inverse {
  background-color: #1a1b1d !important;
  color: #fff;
}

/* ==============================MOMENTS Styles========================= */

.momentModal{
  width: '100vw';
  height: '100vh';
  position: 'fixed';
  z-index: '10';
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  background-color: 'rgba(0,0,0,0.5)';
}

.momentModalCloseIcon{
  position: 'absolute';
  right: '2rem';
  top: '2rem';
  cursor: 'pointer'
}
